import { Link } from 'gatsby';
import React from 'react';

const ResearchList = () => {
  return (
    <section className='faq-one'>
      <div className='container'>
        <div className='row no-gutters'>
        <div className='col-lg-6'>
            <div className='faq-one__single'>              
              <div className='faq-one__content'>
                <h2 className='faq-one__title'>
                  <Link to='/news/sickle-cell'>
                  Post-Transplant Clinic
                  </Link>
                </h2>                
              </div>
            </div>
          </div>
        <div className='col-lg-6'>
            <div className='faq-one__single'>              
              <div className='faq-one__content'>
                <h2 className='faq-one__title'>
                  <Link to='/research/diabetis-melitus'>
                    Overview of Diabetes Mellitus (DM)
                  </Link>
                </h2>                
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='faq-one__single'>              
              <div className='faq-one__content'>
                <h2 className='faq-one__title'>
                  <Link to='/research/hrec'>
                  HEALTH RESEARCH ETHICS COMMITTEE
                  </Link>
                </h2>                
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='faq-one__single'>              
              <div className='faq-one__content'>
                <h2 className='faq-one__title'>
                  <Link to='/research/guidelines-antibiopsy'>
                  ANTIBIOTIC POLICY AND GUIDELINES
                  </Link>
                </h2>                
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='faq-one__single'>             
              <div className='faq-one__content'>
                <h2 className='faq-one__title'>
                  <Link to='/events/2021'>
                    2021 Events
                  </Link>
                </h2>                
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='faq-one__single'>              
              <div className='faq-one__content'>
                <h2 className='faq-one__title'>
                  <Link to='/events/2020'>
                    2020 Events
                  </Link>
                </h2>                
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='faq-one__single'>              
              <div className='faq-one__content'>
                <h2 className='faq-one__title'>
                  <Link to='/events/2019'>
                    2019 Events
                  </Link>
                </h2>              
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='faq-one__single'>              
              <div className='faq-one__content'>
                <h2 className='faq-one__title'>
                  <Link to='/events/2018'>
                    2018 Events
                  </Link>
                </h2>               
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='faq-one__single'>              
              <div className='faq-one__content'>
                <h2 className='faq-one__title'>
                  <Link to='/events/2017'>
                    2017 Events
                  </Link>
                </h2>                
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='faq-one__single'>              
              <div className='faq-one__content'>
                <h2 className='faq-one__title'>
                  <Link to='/events/2016'>
                    2016 Events
                  </Link>
                </h2>                
              </div>
            </div>
          </div>                  
        </div>
      </div>
    </section>
  );
};

export default ResearchList;
